import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {};

const PrivacyPolicy = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Privacy Policy</title>
			</Helmet>
			<div className="pt-20 font-regular">
				<div className="mx-auto container indent-4 policy-text">
					<h1 className="mb-8 text-6xl font-title font-bold text-center">Privacy Policy</h1>
					<p>Effective Date: May 10, 2023</p>
					<p>ThetaWave respects your privacy and is committed to protecting your personal data. This privacy policy explains how we collect, use, disclose, and protect your personal information. ThetaWave, a subsidiary of VODORIA LIMITED, located at Diagorou, 4 KERMIA BUILDING, 5th floor, Office 504, 1097, Nicosia, Cyprus, is the data controller for the processing of your personal data. By using our services, you agree to the collection and use of information in accordance with this policy.</p>
					<p>
						<strong>Information We Collect</strong><br/>
						We collect personal information that you provide to us when you use our services. This information may include your name, email address, phone number, and other contact details. We may also collect information about your use of our services, such as your IP address, device information, and usage data.
					</p>
					<p>
						<strong>How We Use Your Information</strong><br/>
						We use your personal information to provide and improve our services, to communicate with you, and to comply with legal obligations. We may also use your information for marketing purposes, but we will always provide you with an opt-out option.
					</p>
					<p>
						<strong>Disclosure of Your Information</strong><br/>
						We may disclose your personal information to third-party service providers who assist us in providing our services, such as hosting providers, marketing agencies, and payment processors. We may also disclose your information to comply with legal obligations or to protect our rights or the rights of others.
					</p>
					<p>
						<strong>Protecting Your Information</strong><br/>
						We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.
					</p>
					<p>
						<strong>Retention of Your Information</strong><br/>
						We will retain your personal information for as long as necessary to provide our services and for other legitimate business purposes, such as complying with legal obligations or resolving disputes.
					</p>
					<p>
						<strong>Your Rights</strong><br/>
						You have the right to access, correct, and delete your personal information. You may also have the right to restrict or object to certain processing activities. If you wish to exercise these rights, please contact us using the information provided below.
					</p>
					<p>
						<strong>GDPR Compliance</strong><br/>
						We are committed to complying with the EU General Data Protection Regulation (GDPR). If you are a resident of the European Economic Area (EEA), you have certain data protection rights. ThetaWave will take all necessary steps to ensure that your personal data is processed lawfully, fairly, and transparently in accordance with the GDPR.
					</p>
					<p>
						<strong>Changes to this Policy</strong><br/>
						We may update this privacy policy from time to time. The updated policy will be posted on our website with the effective date. We encourage you to review this policy periodically.
					</p>
					<p>
						<strong>Contact Us</strong><br/>
						If you have any questions or concerns about this privacy policy, please contact us at general@vodoria.net
					</p>
				</div>
			</div>
		</>
	);
};

export default PrivacyPolicy;
